// import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { Heading, Box, Image, List, ListItem, Text, SimpleGrid, Stack, Flex, Link } from "@chakra-ui/core"
import HeroImage from "../images/hero-image-1.jpg"

const Header = ({ siteTitle }) => (
  <Box>
    <SimpleGrid overflow="hidden" borderBottom="solid 1px" borderColor="#F1F1F1" as="header" h="100vh" columns={{ xs:1, lg:2}}>
      <Box h={{ xs:"50vh", lg:"auto"}}>
        <Image m="0" w="100%" h="100%" objectFit="cover" src={HeroImage} alt={siteTitle} />
      </Box>
      <Flex h={{ xs:"50vh", lg:"auto"}} alignItems="center" p="5rem" maxW="600px">
        <Stack spacing="1rem">
          <Heading as="h1" fontSize="5xl">
            {siteTitle}
          </Heading>
          <Text fontSize="22px">
            Gamme de catamarans de sport dessinée par les architectes Loday / White et distribuée par Sirena Voile.
          </Text>
          <Text>
            <List>
              <ListItem>
                <Link textDecoration="underline" href="#spitfire">Spitfire</Link>
              </ListItem>
              <ListItem>
                <Link textDecoration="underline" href="#shadow">Shadow</Link>
              </ListItem>
              <ListItem>
                <Link textDecoration="underline" href="#shockwave">Shockwave</Link>
              </ListItem>
              <ListItem>
                <Link textDecoration="underline" href="https://www.wanaboat.fr/marques/chantiers/swell-catamarans/">Bateaux occasion</Link>
              </ListItem>
              <ListItem>
                <Link textDecoration="underline" href="#contact">Contact</Link>
              </ListItem>
            </List>
          </Text>
        </Stack>

      </Flex>
    </SimpleGrid>


  </Box>

)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
